var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{ref:_vm.tableId,attrs:{"query":_vm.query,"variables":_vm.queryVariables,"update":_vm.resolveData},on:{"loading":_vm.changeLoadingStatus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var error = ref_result.error;
var data = ref_result.data;
var isLoading = ref.isLoading;
return [(isLoading)?_c('div',{staticClass:"loading"},[_vm._v(" Data is loading... ")]):(data)?_c('div',[_c('data-table',{attrs:{"data-table-id":_vm.tableId,"loading":isLoading === 1,"header":false,"bordered":_vm.bordered,"columns":_vm.columns,"data":data},scopedSlots:_vm._u([{key:"rating",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('LastAuditRating',{attrs:{"audit":typeof(value) === 'string' ? item : value}})]}},{key:"date",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$id(value, 'date') || '—')+" ")]}}],null,true)})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }