var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tab-view',{attrs:{"tabs":_vm.tabs,"isLoading":_vm.isLoadingTab},on:{"tabChange":_vm.changeActiveTab},scopedSlots:_vm._u([{key:"audits-not-content",fn:function(ref){
var name = ref.name;
return [_c('table-overview',{attrs:{"tableId":name,"columns":_vm.columns,"query":require('@/graphql/queries/global/AuditsNotContent.gql'),"queryVariables":_vm.queryVariables,"resolveData":_vm.resolveNotContentData},on:{"loading":_vm.changeLoadingStatus}})]}},{key:"audits-half-content",fn:function(ref){
var name = ref.name;
return [_c('table-overview',{attrs:{"tableId":name,"columns":_vm.columns,"query":require('@/graphql/queries/global/AuditsHalfContent.gql'),"queryVariables":_vm.queryVariables,"resolveData":_vm.resolveHalfContentData},on:{"loading":_vm.changeLoadingStatus}})]}},{key:"audits-sold",fn:function(ref){
var name = ref.name;
return [_c('table-overview',{attrs:{"tableId":name,"columns":_vm.columns,"query":require('@/graphql/queries/global/AuditsSold.gql'),"queryVariables":_vm.queryVariables,"resolveData":_vm.resolveSoldData},on:{"loading":_vm.changeLoadingStatus}})]}},{key:"audits-expired-inspections",fn:function(ref){
var name = ref.name;
return [_c('table-overview',{attrs:{"tableId":name,"columns":_vm.columnsWithExpiredInspections,"query":require('@/graphql/queries/global/AuditsExpiredInspections.gql'),"queryVariables":_vm.queryVariables,"resolveData":_vm.resolveExpiredInspectionsData},on:{"loading":_vm.changeLoadingStatus}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }