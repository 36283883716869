import i18n from '@/store/i18n';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import {FilterQuerySelectOptions} from '@/layouts/back-office/elements/filters/QuerySelectFilter.vue';

const end = new Date();
const start = new Date(end.getFullYear(), end.getMonth(), 1);

export const auditFilters: Filter[] = [
  {
    key: 'entities',
    title: i18n.it('filter.entities.title', 'Entities') as string,
    type: FilterType.QuerySelect,
    options: {
      query: require('@/graphql/queries/entity/PaginateChildEntities.gql'),
      dataKey: 'paginateChildEntities',
    } as FilterQuerySelectOptions,
  },
  {
    key: 'date',
    title: i18n.it('inspections.filter.date.title', 'Date') as string,
    type: FilterType.Date,
    default: {start, end},
    options: {
      labelStart: i18n.it('global.from', 'From') as string,
      labelEnd: i18n.it('global.until', 'Until') as string,
    },
  },
];
