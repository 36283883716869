





















import {Component, Vue, Prop, Watch, Emit} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

export interface Tab {
  slot: string;
  title: string;
  guttered?: boolean;
  label?: {
    whitespace?: 'normal' | 'nowrap';
  };
}

@Component({
  components: {
    Spinner,
  },
})
export default class TabView extends Vue {
  @Prop(Array) private tabs!: Tab[];
  @Prop(String) private active?: string;
  @Prop(Boolean) private isLoading!: boolean;

  private activeTab: string | null = null;

  get availableTabs() {
    return this.tabs.filter((tab: Tab) => {
      return tab.slot in this.$scopedSlots;
    });
  }

  private mounted() {
    if (this.availableTabs.length > 0) {
      this.changeActiveTab(this.availableTabs[0].slot);
    }
  }

  @Watch('active')
  private changeActiveTab(value: string | null = null) {
    this.activeTab = value;
  }

  @Emit('tabChange')
  private changeTab(value: string) {
    this.activeTab = value;

    return this.activeTab;
  }

  private contentClasses(tab: Tab) {
    return {
      'tab-guttered': (typeof tab.guttered === 'undefined' || tab.guttered),
    };
  }
}
