import i18n from '@/store/i18n';
import {Tab} from '@/layouts/back-office/elements/TabView.vue';

export const dashboardTabs: Tab[] = [
  {
    slot: 'audits-not-content',
    title: i18n.it('global-dashboard.audits-not-content.title', 'Audits Unsatisfactory') as string,
    guttered: false,
    label: {
      whitespace: 'nowrap',
    },
  },
  {
    slot: 'audits-half-content',
    title: i18n.it('global-dashboard.audits-half-content.title', 'Audits With Reservations') as string,
    guttered: false,
    label: {
      whitespace: 'nowrap',
    },
  },
  {
    slot: 'audits-sold',
    title: i18n.it('global-dashboard.audits-sold.title', 'Audits with sold assets') as string,
    guttered: false,
    label: {
      whitespace: 'nowrap',
    },
  },
  {
    slot: 'audits-expired-inspections',
    title:
      i18n.it('global-dashboard.audits-expired-inspections.title', 'Audits with expired inspections') as string,
    guttered: false,
    label: {
      whitespace: 'nowrap',
    },
  },
];
