














































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  AuditsExpiredInspections_globalDashboard,
  AuditsHalfContent_globalDashboard,
  AuditsNotContent_globalDashboard,
  AuditsSold_globalDashboard,
} from '@/types/intrador';
import {dashboardTabs} from '@/components/global-dashboard/DashboardTabs';
import {audits, auditsWithExpiredInspections} from '@/components/global-dashboard/AuditColumns';
import TabView from '@/layouts/back-office/elements/TabView.vue';
import TableOverview from '@/components/global-dashboard/TableOverview.vue';

@Component({
  components: {
    TabView,
    TableOverview,
  },
})
export default class TableTabs extends Vue {
  @Prop(Object) protected queryVariables?: object;

  private tabs = dashboardTabs;
  private isLoadingTab: boolean = false;
  private activeTab: string = 'audits-not-content';
  private columns = audits;
  private columnsWithExpiredInspections = auditsWithExpiredInspections;

  private changeActiveTab(value: string) {
    this.activeTab = value;
  }

  private changeLoadingStatus(value: boolean) {
    this.isLoadingTab = value;
  }

  private resolveNotContentData = (data: any): AuditsNotContent_globalDashboard[] =>
    data.globalDashboard.auditsNotContent

  private resolveHalfContentData = (data: any): AuditsHalfContent_globalDashboard[] =>
    data.globalDashboard.auditsHalfContent

  private resolveSoldData = (data: any): AuditsSold_globalDashboard[] =>
    data.globalDashboard.auditsSoldSituation

  private resolveExpiredInspectionsData = (data: any): AuditsExpiredInspections_globalDashboard[] =>
    data.globalDashboard.auditsExpiredInspections
}
