

































import {Component, Prop, Vue} from 'vue-property-decorator';
import LastAuditRating from '@/components/planning/partials/LastAuditRating.vue';
import DataTable from '@/layouts/back-office/elements/datatable/DataTable.vue';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';

@Component({
  components: {
    LastAuditRating,
    DataTable,
  },
})
export default class TableOverview extends Vue {
  @Prop(String) private tableId!: string;
  @Prop([Function, Object]) private query!: () => void | object;
  @Prop(Object) private queryVariables?: object;
  @Prop([Function, Object]) private resolveData!: () => void | object;
  @Prop({type: Array, required: true}) private columns!: DataTableColumn[];
  @Prop({type: Boolean, default: false}) private bordered: boolean = false;

  private changeLoadingStatus(value: boolean) {
    this.$emit('loading', value);
  }
}
