














































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import {GlobalDashboard_globalDashboard_statistics} from '@/types/intrador';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {
    NumberCard,
    Spinner,
  },
})
export default class StatisticsCards extends Vue {
  @Prop(Object) private statistics!: GlobalDashboard_globalDashboard_statistics | null;
  @Prop(Boolean) private isLoading!: boolean;
}
