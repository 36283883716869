





















import {Component, Mixins} from 'vue-property-decorator';
import AccountManagerFilterMixin from '@/filters/dataTable/AccountManagerFilterMixin';
import {Meta} from '@sophosoft/vue-meta-decorator';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {
  GlobalDashboard_globalDashboard_statistics,
} from '@/types/intrador';
import StatisticsCards from '@/components/global-dashboard/StatisticsCards.vue';
import {audits} from '@/components/global-dashboard/AuditColumns';
import {auditFilters} from '@/components/global-dashboard/AuditFilters';
import TableTabs from '@/components/global-dashboard/TableTabs.vue';

@Component({
  components: {
    TableTabs,
    StatisticsCards,
    QueryDataTable,
  },
})
export default class Dashboard extends Mixins(AccountManagerFilterMixin) {
  private statistics: GlobalDashboard_globalDashboard_statistics | null = null;

  private columns = audits;
  private filters = auditFilters;
  private queryVariables: any = {};

  private changeQueryVariables(variables: any) {
    this.queryVariables = variables;
  }

  private resolveData(globalDashboard: any) {
    this.statistics = globalDashboard.statistics;
  }

  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.global-dashboard.title', 'Global Dashboard'),
    };
  }
}
