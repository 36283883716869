import i18n from '@/store/i18n';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';

export const audits: DataTableColumn[] = [
  {
    title: i18n.it('global.location', 'Location') as string,
    key: 'branch.name',
    class: 'data-table-col-min',
    width: 200,
  },
  {
    title: i18n.it('global.entity', 'Entity') as string,
    tooltip: {
      message: i18n.it('global.entity', 'Entity') as string,
    },
    key: 'branch.owner.name',
    class: 'data-table-col-min',
    width: 200,
  },
  {
    title: i18n.it('global.frequency', 'Frequency') as string,
    tooltip: {
      message: i18n.it('global.frequency', 'Frequency') as string,
    },
    key: 'branch.auditFrequency',
    class: 'data-table-col-fixed data-table-col-center',
    width: 60,
  },
  {
    title: i18n.it('global.next-audit', 'Next Audit') as string,
    key: 'branch.nextAudit',
    slot: 'date',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
  {
    title: i18n.it('global.last-audit', 'Last Audit') as string,
    key: 'closedAt',
    slot: 'date',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
  {
    title: i18n.it('global.rating', 'Rating') as string,
    key: 'rating',
    slot: 'rating',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
  {
    title: i18n.it('global.assets', 'Assets') as string,
    key: 'inspectionsAmount',
    class: 'data-table-col-fixed data-table-col-center',
    width: 60,
  },
  {
    title: i18n.it('global-dashboard.inspections.amount-sold.title', 'Sold') as string,
    key: 'soldAmount',
    class: 'data-table-col-fixed data-table-col-center',
    width: 60,
  },
  {
    title: i18n.it('global.demo', 'Demo') as string,
    key: 'timesInDemoAnomalies',
    class: 'data-table-col-fixed data-table-col-center',
    width: 60,
  },
  {
    title: i18n.it('global.rental', 'Rental') as string,
    key: 'timesInRentalAnomalies',
    class: 'data-table-col-fixed data-table-col-center',
    width: 80,
  },
  {
    title: i18n.it('global.previous-audit', 'Previous audit') as string + ' (1)',
    tooltip: {
      message: i18n.it('global.previous-audit', 'Previous audit') as string + ' (1)',
    },
    key: 'previousAudits[0].closedAt',
    slot: 'date',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
  {
    title: i18n.it('global.rating', 'Rating') as string,
    key: 'previousAudits[0]',
    slot: 'rating',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
  {
    title: i18n.it('global.previous-audit', 'Previous audit') as string + ' (2)',
    tooltip: {
      message: i18n.it('global.previous-audit', 'Previous audit') as string + ' (2)',
    },
    key: 'previousAudits[1].closedAt',
    slot: 'date',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
  {
    title: i18n.it('global.rating', 'Rating') as string,
    key: 'previousAudits[1]',
    slot: 'rating',
    class: 'data-table-col-fixed data-table-col-center',
    width: 100,
  },
];

const expiredColumn: DataTableColumn = {
  title: i18n.it('global.expired', 'Expired') as string,
  key: 'expiredInspections',
  class: 'data-table-col-fixed data-table-col-center',
  tooltip: {
    message: i18n.it('global.expired', 'Expired') as string,
  },
  width: 60,
};

function addColumn(
  currentColumns: DataTableColumn[], columnToAdd: DataTableColumn, startIndex: number): DataTableColumn[] {
  const newColumns = currentColumns.slice();
  newColumns.splice(startIndex, 0, columnToAdd);
  return newColumns;
}

export const auditsWithExpiredInspections: DataTableColumn[] = addColumn(audits, expiredColumn, 7);
